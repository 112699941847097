import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import loader from './loader'
import app from './app'
import appConfig from './app-config'
import auth from './auth/auth'
import company from './company'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    loader,
    auth,
    company,
  },
  strict: process.env.DEV,
})
