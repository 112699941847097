import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboards/Analytics.vue'),
      meta: {
        pageTitle: 'Dashboards',
        breadcrumb: [
          {
            text: 'Resumo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: () => import('@/views/users/UsersList.vue'),
      meta: {
        pageTitle: 'Usuários',
        breadcrumb: [
          {
            text: 'Usuários',
            active: true,
          },
        ],
      },
    },
    {
      path: '/minha-conta',
      name: 'minha-conta',
      component: () => import('@/views/users/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Minha Conta',
        breadcrumb: [
          {
            text: 'Minha Conta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dispositivos',
      name: 'dispositivos',
      component: () => import('@/views/devices/DeviceList.vue'),
      meta: {
        pageTitle: 'Dispositivos',
        breadcrumb: [
          {
            text: 'Dispositivos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/empresa',
      name: 'empresa',
      component: () => import('@/views/company/CompanyList.vue'),
      meta: {
        pageTitle: 'Cadastro de Empresa',
        breadcrumb: [
          {
            text: 'Cadastro de Empresa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/relatorio-monitoramento',
      name: 'monitoring-table',
      component: () => import('@/views/reports/MonitoringTable'),
      meta: {
        pageTitle: 'Tabelas de Monitoramento',
        breadcrumb: [
          {
            text: 'Tabelas de Monitoramento',
            active: true,
          },
        ],
      },
    },
    {
      path: '/empresa/:uuid/unidade',
      name: 'empresa-unidade',
      component: () => import('@/views/company/unity/UnityList.vue'),
      meta: {
        pageTitle: 'Cadastro de Unidade',
        breadcrumb: [
          {
            text: 'Cadastro de Unidade',
            active: true,
          },
        ],
      },
    },
    {
      path: '/importar-arquivo',
      name: 'file-upload',
      component: () => import('@/views/upload-data-file/Index.vue'),
      meta: {
        pageTitle: 'Importar arquivo de leitura',
        breadcrumb: [
          {
            text: 'Importar arquivo de leitura',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/perdi-minha-senha',
      name: 'perdi-minha-senha',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recuperar-senha/:token',
      name: 'recuperar-senha',
      component: () => import('@/views/auth/ResetPassword'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'page-error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
  || to.name === 'recuperar-senha') {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn()) {
      window.location.href = '/login'
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
