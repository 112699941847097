const state = {
  companies: null,
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_COMPANIES(state, payload) {
    state.companies = payload
  },
}

const actions = {
  getCompanies: async ({ commit }) => {
    try {
      const data = await window.http.get('company?include=unities')
      commit('SET_COMPANIES', data)
      return data
    } catch (error) {
      return error
    }
  },
  // eslint-disable-next-line no-unused-vars
  getUnities: async ({ commit }, company) => {
    try {
      return await window.http.get(`company/${company}/unitys`)
    } catch (error) {
      return error
    }
  },
  setCompanies: async ({ commit }, companies) => {
    commit('SET_COMPANIES', companies)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
