import Vue from 'vue'
import {
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BForm,
  BAlert,
  BBadge,
  BTabs,
  BTab,
  BImg,
  BCard,
  BFormGroup, BDropdownItem, BDropdown, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.component('BFormInput', BFormInput)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BButton', BButton)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BForm', BForm)
Vue.component('BAlert', BAlert)
Vue.component('BBadge', BBadge)
Vue.component('BTabs', BTabs)
Vue.component('BTab', BTab)
Vue.component('BImg', BImg)
Vue.component('BCard', BCard)
Vue.component('BCardCode', BCardCode)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BDropdown', BDropdown)
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback)
Vue.component('vSelect', vSelect)
Vue.component('ToastificationContent', ToastificationContent)
